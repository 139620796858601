import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "react"
    }}>{`React`}</h1>
    <p>{`For projects that are creating react we suggest the following start. A "basic-example" project is also available at:`}</p>
    <h2 {...{
      "id": "basics"
    }}>{`Basics`}</h2>
    <p>{`Create the app using the create-react-app CLI tool:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npx create-react-app basic-example
`}</code></pre>
    <p>{`This will create a basic setup that includes:`}</p>
    <ul>
      <li parentName="ul">{`Latest ReactJS version (16.13.1)`}</li>
      <li parentName="ul">{`Webpack + Webpack dev server`}</li>
      <li parentName="ul">{`Babel`}</li>
      <li parentName="ul">{`Production ready script`}</li>
    </ul>
    <p>{`Almost any app requires a router, for web-apps react-router-dom can be used:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install react-router-dom --save
`}</code></pre>
    <h2 {...{
      "id": "typing"
    }}>{`Typing`}</h2>
    <h4 {...{
      "id": "typescript"
    }}>{`Typescript`}</h4>
    <p>{`Typing is always an interesting point. Please keep it at plain Javascript. React and a lot of the 3rd libraries are not properly typed for Typescript usage which creates package version issues and manual typing. `}</p>
    <h4 {...{
      "id": "flow"
    }}>{`Flow`}</h4>
    <p>{`Setting up flow is even more tedious and does not add to the improvement of the project.`}</p>
    <h4 {...{
      "id": "proptypes"
    }}>{`PropTypes`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.npmjs.com/package/prop-types"
      }}>{`PropTypes`}</a>{` are a easy and powerfull addition to a project. It does not take a lot of effort and streamlines the usage of API's / services in your project. Add the typings to places that are called from multiple locations, it helps other developers manage and implement the same services / api.`}</p>
    <h2 {...{
      "id": "statemanagement"
    }}>{`Statemanagement`}</h2>
    <p>{`With the advent of `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-intro.html"
      }}>{`React Hooks`}</a>{` and `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/context.html"
      }}>{`React Context`}</a>{` state management libraries are redundant. Redux or Mobx does not have a place in the current React development meta. A Higher Order Component can be written and used, but please try to use it sparingly. `}</p>
    <h2 {...{
      "id": "api-management"
    }}>{`Api Management`}</h2>
    <h4 {...{
      "id": "graphql"
    }}>{`GraphQL`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.apollographql.com/docs/react/"
      }}>{`Apollo Client`}</a></p>
    <h4 {...{
      "id": "rest"
    }}>{`REST`}</h4>
    <p><a parentName="p" {...{
        "href": "https://www.npmjs.com/package/axios"
      }}>{`Axios`}</a></p>
    <h2 {...{
      "id": "timemanagement"
    }}>{`Timemanagement`}</h2>
    <p><a parentName="p" {...{
        "href": "https://js-joda.github.io/js-joda/"
      }}>{`js-joda`}</a>{` is a good fast library that does not extend / use the native Date object. `}</p>
    <h2 {...{
      "id": "testing"
    }}>{`Testing`}</h2>
    <p>{`React offers a complete unit testing setup from the start. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      